<template>
  <div
    class="row items-center fit"
    :class="hasMaxWidth ? 'full-width' : 'justify-center'"
  >
    <q-form @submit="onSubmit" class="col row items-center bg-grey-5 rounded q-pl-sm">
      <q-input
        ref="barcodeInput"
        v-model="barcode"
        type="text"
        color="white"
        label-color="white"
        class="col"
        :label="$t('Barcode')"
        :inputmode="inputmode"
        :dense="dense"
        :autofocus="true"
        @focus="onFocus"
        @blur="onFocusOut"
        @dblclick="handleClick"
      />

      <q-toggle
        v-if="settings.catchAll"
        :model-value="catchAll"
        :disable="disabled"
        :class="$q.dark.isActive ? 'text-white' : ''"
        class="terminal-toggle full-height"
        style="transform: rotate(90deg)"
        dense
        @update:model-value="handleCatchChange"
      />
    </q-form>

    <q-dialog
      :model-value="isOpenCamera"
      persistent
      :maximized="true"
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <q-card class="bg-primary text-white">
        <q-bar>
          <q-toolbar-title>
            {{ $t('Barcode') }}
          </q-toolbar-title>

          <q-space />

          <q-btn
            dense
            flat
            @click="closeCamera"
            icon="close"
          >
            <q-tooltip content-class="bg-white text-primary">
              {{ $t('Close') }}
            </q-tooltip>
          </q-btn>
        </q-bar>

        <barcode-scanner
          v-if="isOpenCamera"
          @change="logIt"
        />
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
// Components
import BarcodeScanner from './../barcode-scanner/BarcodeScanner'

// Quasar
import { debounce } from 'quasar'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'BarcodeInput',
  emits: ['barcode'],
  components: {
    BarcodeScanner
  },
  props: {
    dense: {
      type: Boolean,
      default () {
        return false
      }
    },
    settings: {
      type: Object,
      default () {
        return {
          catchAll: true,
          hideKeyboard: false
        }
      }
    },
    hasMaxWidth: {
      type: Boolean,
      default () {
        return true
      }
    },
    outFocused: {
      type: Boolean,
      default () {
        return false
      }
    },
    isHiddenCamera: {
      type: Boolean,
      default () {
        return false
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      barcode: '',
      isFocused: false,
      catchAll: true,
      inputmode: 'text',
      readerSize: {
        width: 400,
        height: 480
      },
      token: null,
      inputWidth: 80,
      sub: null
    }
  },
  computed: {
    ...mapGetters([
      'isOpenCamera'
    ])
  },
  watch: {
    settings: {
      handler (newVal, oldVal) {
        if (newVal.catchAll !== oldVal.catchAll) {
          this.recognizedListener()
        }

        if (newVal.hideKeyboard !== oldVal.hideKeyboard) {
          this.recognizedInputmode()
        }
      },
      deep: true
    }
  },
  mounted () {
    this.attachCatchAll()
    this.recognizedInputmode()

    this.resize()
    this.resize = debounce(this.resize.bind(this), 500)
    this.sub = this.$eventBus.subscribe('helper-move', () => {
      if (this.isFocused) {
        this.$refs.barcodeInput.blur()
      }

    })

    window.addEventListener('resize', this.resize)
  },
  unmounted () {
    document.removeEventListener('keydown', this.handleAll)
    window.removeEventListener('resize', this.resize)
    this.sub.unsubscribe()
  },
  methods: {
    ...mapMutations([
      'openCamera',
      'closeCamera',
      'openCloseCamera'
    ]),
    recognizedListener () {
      if (this.settings.catchAll) {
        this.attachCatchAll()
      } else {
        this.unattachCatchAll()
      }
    },
    recognizedInputmode () {
      this.inputmode = !this.settings.hideKeyboard
        ? 'text'
        : 'none'
    },
    unattachCatchAll () {
      document.removeEventListener('keydown', this.handleAll)
    },
    attachCatchAll () {
      if (!this.settings.catchAll) {
        return
      }

      this.handleAll = this.handleAll.bind(this)
      document.addEventListener('keydown', this.handleAll)
    },
    resize () {
      this.inputWidth = window.innerWidth < 600 && this.settings.catchAll
        ? 60
        : 80
    },
    logIt (barcode) {
      this.barcode = barcode
      this.onSubmit()
      this.closeCamera()
    },
    handleClick () {
      if (!this.settings.hideKeyboard) {
        return
      }

      this.inputmode = 'text'
    },
    onFocus () {
      this.isFocused = true
      this.catchAll = false
    },
    onFocusOut () {
      if (this.settings.hideKeyboard) {
        this.inputmode = 'none'
      }

      this.catchAll = true
      this.isFocused = false
    },
    handleCatchChange () {
      this.catchAll = !this.catchAll
    },
    handleAll (e) {
      if (!this.catchAll || this.outFocused || e.ctrlKey || e.altKey) {
        return
      }

      this.$refs.barcodeInput.focus()
    },
    createBarcodeData (barcode) {
      const raw = `${barcode}`.trim()
      const expr = /[A-z/]+\/([0-9A-z/]+)/

      if (!raw.match(expr)) {
        return {
          raw,
          value: raw,
          type: ''
        }
      }

      const data = raw
        .split('*')[0]
        .split('/')
        .reduce((acc, value) => {
          if (isNaN(Number(value)) && !acc.value) {
            acc.type += `${value}/`
          } else {
            acc.value += `${value}/`
          }

          return acc
        }, { value: '', type: '', raw })

      data.value = data.value.slice(0, data.value.length - 1)
      data.type = data.type.slice(0, data.type.length - 1)

      return data
    },
    onSubmit (e) {
      if (e) {
        e.preventDefault()
      }

      if (!this.barcode) {
        return
      }

      this.$emit('barcode', this.createBarcodeData(this.barcode))
      this.barcode = ''
    }
  }
}
</script>
